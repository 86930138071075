<template>
  <section>
    <script v-html="googleCourseMetaList" type="application/ld+json"></script>
    <v-container class="mt-4">
      <div>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <span class="friendly-3">
              Courses available
            </span>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn class="warning hidden-sm-and-down" style="float:right;" @click="$router.push({ name: 'welcomeToDiving' })">
              <font-awesome-icon size="lg" :icon="['fas', 'circle-info']" fixed-width></font-awesome-icon>
              <span class="ml-2">I'm new to Scuba Diving</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn block class="warning mt-3 hidden-md-and-up" @click="$router.push({ name: 'welcomeToDiving' })">
          <font-awesome-icon size="lg" :icon="['fas', 'circle-info']" fixed-width></font-awesome-icon>
          <span class="ml-2">I'm new to Scuba Diving</span>
        </v-btn>
      </div>
      <div class="mt-4">
        <v-row>
          <v-col cols="12" lg="3" md="5" sm="6">
            <v-text-field outlined label="Search Course Name" v-model="search">
              <font-awesome-icon slot="prepend-inner" :icon="['fas', 'magnifying-glass']" fixed-width></font-awesome-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="3" md="5" sm="6" offset-md="2" offset-lg="6" class="hidden-xs-only">
            <v-card outlined>
              <v-card-text>
                <div>
                  Here at Sea2Sea Scuba we offer a range of courses from never been diving, to advanced technical training. You can browse our catalog or search for a course we offer.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-if="!isLoading && courses.length === 0" class="text-center">
        <div class="my-12">It seems there are no courses available</div>
        <v-btn outlined width="300" color="primary" class="mt-2" @click="doCallBusiness">
          <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
          <span class="ml-2">Call for courses</span>
        </v-btn>
      </div>
      <div v-else class="mt-4">
        <v-row>
          <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="course in currentCourses" :key="course.id">
            <v-card elevation="10" class="rounded-lg" @click="doOpenCourse(course.id)">
              <v-img :aspect-ratio="1" :src="(course.images[0] !== undefined && 'url' in course.images[0] ? course.images[0].url : `https://via.placeholder.com/1920x1080.png?text=${course.name} Course`)">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-row>
                </template>
                <div class="title-gradient-background">
                  <v-avatar size="42" class="ma-2">
                    <v-img contain :src="course.organization.logoUrl"></v-img>
                  </v-avatar>
                  <span class="font-weight-bold white--text">{{ course.organization.name }}</span>
                  <v-chip class="font-weight-bold ma-3 float-end hidden-md-and-down">
                    ${{ course.cents / 100 }}
                  </v-chip>
                </div>
              </v-img>
              <v-card-title>
                <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ course.name }}</p>
              </v-card-title>
              <v-card-subtitle style="height: 100px;">
                <p v-snip="{ lines: 4, mode: 'js', midWord: true }">
                  {{ course.subtitle }}
                </p>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-pagination
          class="my-4"
          v-model="page.current"
          :length="page.total"
          @input="doScrollToTop"
        ></v-pagination>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'About',
  data: () => ({
    isLoading: false,
    search: String(),
    maxPerPage: 8,
    page: {
      current: 1,
      total: 1
    },
    courses: []
  }),
  methods: {
    doOpenCourse (id) {
      this.$router.replace({ name: 'view-course', params: { id: id } })
    },
    doLoad () {
      this.isLoading = true

      this.$services.course.doGetAllPublic().then(courses => {
        this.courses = courses

        this.onResize()
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    onResize () {
      const innerWidth = window.innerWidth

      if (innerWidth < 600) {
        this.maxPerPage = 4
      } else if (innerWidth >= 600 && innerWidth < 960) {
        this.maxPerPage = 4
      } else if (innerWidth >= 960 && innerWidth < 1264) {
        this.maxPerPage = 9
      } else if (innerWidth >= 1264 && innerWidth < 1904) {
        this.maxPerPage = 8
      } else if (innerWidth >= 1904) {
        this.maxPerPage = 12
      } else {
        this.maxPerPage = 12
      }

      this.page.total = Math.ceil(this.allCoursesWithSearch.length / this.maxPerPage)
    }
  },
  mounted () {
    this.doLoad()
    if (this.$route.params.search) {
      this.search = this.$route.params.search
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    allCoursesWithSearch () {
      if (this.search.length >= 3) {
        let foundExact = null

        const search = this.search.replace(/[^\w\s!?]/g, '').toLowerCase().trimEnd()

        const results = this.courses.filter(course => {
          if (course.name.toLowerCase() === search) {
            foundExact = course
          } else {
            return course.name.toLowerCase().includes(search)
          }
        })

        if (foundExact !== null) {
          return [foundExact]
        } else {
          return results
        }
      }

      return this.courses
    },
    currentCourses () {
      const pages = []

      for (let i = 0; i < this.allCoursesWithSearch.length; i += this.maxPerPage) {
        pages.push(this.allCoursesWithSearch.slice(i, i + this.maxPerPage))
      }

      return pages[this.page.current - 1]
    },
    googleCourseMetaList () {
      const courseListMeta = []

      this.courses.forEach(course => {
        courseListMeta.push(this.doGenerateCourse(course.name, course.subtitle, course.organization.name, course.organization.homepage))
      })

      const listObject = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: courseListMeta
      }

      return listObject
    }
  },
  watch: {
    search () {
      this.page.current = 1
      this.page.total = Math.ceil(this.allCoursesWithSearch.length / this.maxPerPage)
    }
  },
  created () {
    this.doSetDocumentTitle('Courses and Classes', true)
  }
}
</script>

<style scoped lang="scss">
.title-gradient-background {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,0.5) 0%, rgba(255,255,255,0.3) 100%);
}
</style>
